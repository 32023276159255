import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { ReactComponent as BackIcon } from "../../icons/back-icon.svg";

const UlDropdown = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  background: ${(props) => props.theme.primaryDark};
  padding: 0.75rem 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  /* The emerging W3C standard
   that is currently Firefox-only */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.primaryLight}
    ${(props) => props.theme.primaryDark};

  /* Works on Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.primaryLight};
    border-radius: 20px;
    border: 2px solid ${(props) => props.theme.primary};
  }

  li {
    margin-left: 1rem;
    padding: 0.25rem;

    a {
      display: inline-block;
      width: 100%;
    }

    a:hover {
      color: #ffffff;
      text-decoration: underline;
    }

    button {
      font-size: 12px;
      padding: 9px 12px;

      svg {
        margin-right: 0.5rem;
        transform: translateY(1px);
        height: 12px;
      }
    }

    button:hover {
      color: #ffffff;
      text-decoration: underline;
    }

    button.active {
      background-color: ${(props) => props.theme.primaryDark};
      border-left: 5px solid ${(props) => props.theme.secondary};
    }
  }
`;

const UploadMenu = styled.div`
  background: ${(props) => props.theme.primaryDark};
  color: ${(props) => props.theme.background};
  position: relative;
`;

//REINF: { "1000", "1050", "1070", "2010", "2020", "2030", "2040", "2050", "2055", "2060", "2098", "2099", "3010", "4010", "4020", "4040", "4080", "4099", "9000", "9001", "9005", "9011"}
//ESOCIAL: { "1000", "1005", "1010", "1020", "1030", "1035", "1040", "1050", "1060", "1070", "1080", "1200", "1202", "1207", "1210", "1250", "1260", "1270", "1280", "1295", "1298", "1299", "1300", "2190", "2200", "2205", "2206", "2210", "2220", "2221", "2230", "2231", "2240", "2245", "2250", "2260", "2298", "2299", "2300", "2306", "2399", "2400", "2405", "2410", "2416", "2418", "2420", "2500", "2501", "3000", "5001", "5002", "5003", "5011", "5012", "5013", "5501", "8299" }
const reinf = [
  "1000",
  "1050",
  "1070",
  "2010",
  "2020",
  "2030",
  "2040",
  "2050",
  "2055",
  "2060",
  "2098",
  "2099",
  "3010",
  "4010",
  "4020",
  "4040",
  "4080",
  "4099",
  "9000",
  "9001",
  "9005",
  "9011",
];

const esocial = [
  "1000",
  "1005",
  "1010",
  "1020",
  "1030",
  "1035",
  "1040",
  "1050",
  "1060",
  "1070",
  "1080",
  "1200",
  "1202",
  "1207",
  "1210",
  "1250",
  "1260",
  "1270",
  "1280",
  "1295",
  "1298",
  "1299",
  "1300",
  "2190",
  "2200",
  "2205",
  "2206",
  "2210",
  "2220",
  "2221",
  "2230",
  "2231",
  "2240",
  "2245",
  "2250",
  "2260",
  "2298",
  "2299",
  "2300",
  "2306",
  "2399",
  "2400",
  "2405",
  "2410",
  "2416",
  "2418",
  "2420",
  "2500",
  "2501",
  "3000",
  "5001",
  "5002",
  "5003",
  "5011",
  "5012",
  "5013",
  "5501",
  "8299",
];

const UploadDropdown = () => {
  const [tipo, setTipo] = useState("");

  return (
    <UlDropdown>
      {tipo === "" ? (
        <UploadMenu>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/icms"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("icms") ||
                  location.pathname.toLowerCase().endsWith("icms/")
                )
                  return true;
              }}
            >
              EFD-ICMS
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/contrib"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("contrib") ||
                  location.pathname.toLowerCase().endsWith("contrib/")
                )
                  return true;
              }}
            >
              EFD-CONTRIB
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/contabil"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("contabil") ||
                  location.pathname.toLowerCase().endsWith("contabil/")
                )
                  return true;
              }}
            >
              ECD
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/ecf"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("ecf") ||
                  location.pathname.toLowerCase().endsWith("ecf/")
                )
                  return true;
              }}
            >
              ECF
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/perdcomp"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("perdcomp") ||
                  location.pathname.toLowerCase().endsWith("perdcomp/")
                )
                  return true;
              }}
            >
              PERDCOMP
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/gia"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("gia") ||
                  location.pathname.toLowerCase().endsWith("gia/")
                )
                  return true;
              }}
            >
              GIA
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/dctf"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("dctf") ||
                  location.pathname.toLowerCase().endsWith("dctf/")
                )
                  return true;
              }}
            >
              DCTF
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cat42"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cat42") ||
                  location.pathname.toLowerCase().endsWith("cat42/")
                )
                  return true;
              }}
            >
              CAT-42
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cat207"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cat207") ||
                  location.pathname.toLowerCase().endsWith("cat207/")
                )
                  return true;
              }}
            >
              CAT-207
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cat83"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cat83") ||
                  location.pathname.toLowerCase().endsWith("cat83/")
                )
                  return true;
              }}
            >
              CAT-83
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/nfe_entrada"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("nfe_entrada") ||
                  location.pathname.toLowerCase().endsWith("nfe_entrada/")
                )
                  return true;
              }}
            >
              NF-e Entrada
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/nfe_saida"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("nfe_saida") ||
                  location.pathname.toLowerCase().endsWith("nfe_saida/")
                )
                  return true;
              }}
            >
              NF-e Saída/NFCe
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/evento_nfe"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("evento_nfe") ||
                  location.pathname.toLowerCase().endsWith("evento_nfe/")
                )
                  return true;
              }}
            >
              Evento NFe
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/nfse"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("nfse") ||
                  location.pathname.toLowerCase().endsWith("nfse/")
                )
                  return true;
              }}
            >
              NFSe
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cfe"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cfe") ||
                  location.pathname.toLowerCase().endsWith("cfe/")
                )
                  return true;
              }}
            >
              CF-e
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cte"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cte") ||
                  location.pathname.toLowerCase().endsWith("cte/")
                )
                  return true;
              }}
            >
              CT-e
            </NavLink>
          </li>
          <li>
            <button
              onClick={() => setTipo("reinf")}
              className={
                location.pathname.toLowerCase().includes("reinf")
                  ? "active"
                  : ""
              }
            >
              REINF
            </button>
          </li>
          <li>
            <button
              onClick={() => setTipo("esocial")}
              className={
                location.pathname.toLowerCase().includes("esocial")
                  ? "active"
                  : ""
              }
            >
              eSocial
            </button>
          </li>
        </UploadMenu>
      ) : tipo === "reinf" ? (
        <UploadMenu>
          <li>
            <button onClick={() => setTipo("")}>
              <BackIcon /> Voltar
            </button>
          </li>
          {reinf.map((item) => (
            <li>
              <NavLink
                activeClassName="active"
                to={`/storage/reinf_${item}`}
                isActive={(match, location) => {
                  if (
                    location.pathname.toLowerCase().endsWith(`reinf_${item}`) ||
                    location.pathname.toLowerCase().endsWith(`reinf_${item}`)
                  )
                    return true;
                }}
              >
                REINF {item}
              </NavLink>
            </li>
          ))}
        </UploadMenu>
      ) : tipo === "esocial" ? (
        <UploadMenu>
          <li>
            <button onClick={() => setTipo("")}>
              <BackIcon /> Voltar
            </button>
          </li>
          {esocial.map((item) => (
            <li>
              <NavLink
                activeClassName="active"
                to={`/storage/esocial_${item}`}
                isActive={(match, location) => {
                  if (
                    location.pathname
                      .toLowerCase()
                      .endsWith(`esocial_${item}`) ||
                    location.pathname.toLowerCase().endsWith(`esocial_${item}`)
                  )
                    return true;
                }}
              >
                eSocial {item}
              </NavLink>
            </li>
          ))}
        </UploadMenu>
      ) : (
        <UploadMenu>
          <li>
            <button onClick={() => setTipo("")}>
              <BackIcon /> Voltar
            </button>
          </li>
        </UploadMenu>
      )}
    </UlDropdown>
  );
};

export default UploadDropdown;
